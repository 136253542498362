import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { CredentialListItemsService, EligibilityService, HouseholdProfilesService } from '@se-po/shared-data-access-services'
import { environment } from '@se-po/shared-environments'
import { DateTime } from 'luxon'
import { Subscription } from 'rxjs'
import { SeFeMenuComponent, SeFeMenuOptions } from 'se-fe-menu'
import { SeFeTableComponent, SeFeTableDataSource, SeFeTableSortDirective } from 'se-fe-table'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'
import { PersonaEligibility } from 'se-resource-types/dist/lib/EligibilitySearchService'

@Component({
  selector: 'se-po-credential-table',
  templateUrl: './credential-table.component.html',
  styleUrls: ['./credential-table.component.scss']
})
export class CredentialTableComponent implements OnInit, OnDestroy {

  @ViewChild(SeFeTableComponent) public table: SeFeTableComponent<ElementRef>
  @ViewChild(SeFeTableSortDirective) public sorter: SeFeTableSortDirective
  @ViewChildren(SeFeMenuComponent) public menus: QueryList<SeFeMenuComponent>

  public chipOrgOpts = {}
  public chipPersonaOpts = {}
  public dataSource: SeFeTableDataSource
  public displayedColumns = [
    'credential',
    'status',
    'validityDates',
    'person'
  ]
  public displayPersonFilterCount = 0
  public displayStatusFilterCount = 0
  public filtersForm: FormGroup
  public filtersFormReady: boolean
  public organizations: any[]
  public organizationForm: FormGroup
  public organizationFormSubscription: Subscription
  public organizationFormReady: boolean
  public listItems: any
  public loading = true
  public orgFilterTitle: string
  public orgMenuOptions: SeFeMenuOptions = {
    name: 'orgMenu'
  }
  public organizationId: number
  public organizationOptions: any
  public personas: Persona[]
  public personaIds: number[]
  public personOptions = {}
  public personMenuOptions: SeFeMenuOptions = {
    name: 'personMenu',
    maxHeight: 'auto'
  }
  public personFilterTitle = 'Person'
  public statusFilterTitle = 'Status'
  public refreshEmptyStateTitle: string
  public showChipAvatar: boolean
  public usavPersonaEligibilities: PersonaEligibility[] = []
  public showSoccerLaunchBanner: boolean
  public soccerLogo = `${environment.uiThemes}/assets/latest/images/usslc-logo.png`
  public statusCheckOptions = [
    {
      label: 'Active',
      value: 'Active'
    },
    {
      label: 'Expired',
      value: 'Expired'
    },
    {
      label: 'Expiring Soon',
      value: 'Expiring Soon'
    }
  ]
  public statusMenuOptions: SeFeMenuOptions = {
    name: 'statusMenu',
    maxHeight: 'auto'
  }

  private sorterSubscription: Subscription

  constructor(
    private credentialListItemsService: CredentialListItemsService,
    private eligibilityService: EligibilityService,
    private formBuilder: FormBuilder,
    private householdProfilesService: HouseholdProfilesService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  private get selectedPersonFilters(): any[] {
    if (this.filtersForm.get('personFilters').value) {
      return Object.values(this.filtersForm.get('personFilters').value).filter(value => value)
    }
    return []
  }

  private get selectedStatusFilters(): any[] {
    if (this.filtersForm.get('statusFilters').value !== null) {
      return this.filtersForm.get('statusFilters').value
    }
    return []
  }

  public async ngOnInit(): Promise<void> {
    const profiles = await this.householdProfilesService.findMyProfiles('manager').toPromise()
    this.personas = profiles.map(p => p.persona)
    this.personaIds = this.personas.map((persona) => persona.id)
    const personaEligibilities = await this.eligibilityService.findAll(this.personaIds, 1000).toPromise()
    this.usavPersonaEligibilities = personaEligibilities.filter((pe) => pe.organization_ids.includes(22518))
    this.showSoccerLaunchBanner = !!personaEligibilities.find((pe) => pe.organization_ids.includes(69675))
    const linkedRuleSetIds = this.getLinkedEligibilityRuleSetIds(personaEligibilities)
    this.organizations = personaEligibilities.reduce((acc: any, cur: any) => {
      const duplicate = acc.find((org) => org.id === cur.eligibility_rule_set.boss_organization.id)
      const parentRuleSet = linkedRuleSetIds.includes(cur.eligibility_rule_set_id)
      if (!duplicate && !parentRuleSet) {
        acc.push(cur.eligibility_rule_set.boss_organization)
      }
      return acc
    }, [])
    this.organizationOptions = this.organizations.map((org) => ({
      imgURL: org.org_logo?.find((logo) => logo.image_type === 'small')?.url || '',
      label: org.name,
      value: org.id
    }))
    this.organizationForm = this.formBuilder.group({ organization: '' })
    this.filtersForm = new FormGroup({
      statusFilters: new FormControl(),
      personFilters: new FormGroup({})
    })
    this.personas.map((persona) => {
      this.personOptions[persona.id] = {
        checkboxOpts: {
          label: persona.full_name,
          value: persona.id
        },
        image: this.setImageUrl(persona)
      }
      const parentControl = this.filtersForm.get('personFilters') as FormGroup
      const control = new FormControl(false)
      parentControl.addControl(persona.id.toString(), control)
    })
    await this.checkQueryParams()
    this.organizationFormSubscription = this.organizationForm.valueChanges.subscribe((changes) => {
      if (this.organizationId !== changes.organization) {
        this.organizationId = Number(changes.organization)
        this.menus.forEach((menu) => menu.visible && menu.closeMenu())
        this.listItems = []
        if (this.dataSource) {
          this.dataSource.data = []
        }
        this.loadTable()
      }
    })
    this.loading = false
    this.filtersFormReady = true
    this.organizationFormReady = true
  }

  public ngOnDestroy(): void {
    this.organizationFormSubscription?.unsubscribe()
    this.sorterSubscription?.unsubscribe()
  }

  public async applyFilters(): Promise<void> {
    this.dataSource.data = this.listItems
    await this.applyPersonFilters()
    await this.applyStatusFilters()
    this.setTitles()
    this.menus.forEach((menu) => menu.visible && menu.closeMenu())
  }

  public clearAllFilters(): void {
    this.filtersForm.reset()
    this.applyFilters()
  }

  public clearFilter(formControlName?: string): void {
    this.filtersForm.get(formControlName).reset()
    this.applyFilters()
  }

  public setChipAvatar(): void {
    if (this.selectedPersonFilters.length === 1) {
      const persona = this.personas.find((p) => this.filtersForm.get('personFilters').value[p.id.toString()] === true)
      this.chipPersonaOpts = {
        identity: persona.full_name,
        imgURL: this.setImageUrl(persona)
      }
      this.showChipAvatar = true
    } else {
      this.showChipAvatar = false
    }
  }

  private async loadTable(): Promise<void> {
    this.loading = true
    const selectedOrg = this.organizations.find((org) => org.id === this.organizationId)
    this.orgFilterTitle = selectedOrg.name
    this.chipOrgOpts = {
      identity: selectedOrg.name,
      imgURL: selectedOrg.org_logo?.find((logo) => logo.image_type === 'small')?.url || ''
    }
    this.listItems = await this.credentialListItemsService.getListItems(this.organizationId, this.personaIds)
    this.dataSource = new SeFeTableDataSource(this.listItems, this.sorter)
    this.sorterSubscription = this.sorter.seFeSortChange.subscribe((event) => this.onSort(event))
    this.table.dataSource = this.dataSource
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { orgId: this.organizationId },
      queryParamsHandling: 'merge'
    })
    await this.applyFilters()
    this.organizationForm.get('organization').setValue(this.organizationId)
    this.loading = false
  }

  private setTitles(): void {
    const firstName = this.getPersonFilterFirstName()
    this.personFilterTitle = firstName || 'Person'
    this.displayPersonFilterCount = this.selectedPersonFilters?.length || 0
    this.displayStatusFilterCount = this.selectedStatusFilters?.length
    this.statusFilterTitle = (this.selectedStatusFilters?.length === 1 ? this.selectedStatusFilters[0] : 'Status')
    this.refreshEmptyStateTitle = firstName ? `No results found for ${firstName}` : 'No results found'
  }

  private getPersonFilterFirstName(): string {
    if (this.selectedPersonFilters.length === 1) {
      return this.personas.find((persona) => this.filtersForm.get('personFilters').value[persona.id.toString()] === true)?.first_name
    }
  }

  private async applyPersonFilters(): Promise<void> {
    this.setChipAvatar()
    if (this.atLeastOnePersonaSelected()) {
      this.dataSource.data = this.dataSource.data.filter(item => {
        const pId = item.personaId.toString()
        return this.filtersForm.get('personFilters').value[pId] === true
      })
    }
    const personFilters = Object.entries(this.filtersForm.value.personFilters)
      .filter(([key, value]) => value).map(([key]) => key).join() || null
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { personFilters },
      queryParamsHandling: 'merge'
    })
  }

  private atLeastOnePersonaSelected(): boolean {
    return Object.values(this.filtersForm.get('personFilters').value).some(value => value === true)
  }

  private async applyStatusFilters(): Promise<void> {
    if (this.filtersForm.value.statusFilters?.length) {
      this.dataSource.data = this.dataSource.data.filter((listItem) =>
        this.filtersForm.value.statusFilters.includes(listItem.chip.text)
      )
    }
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { statusFilters: this.filtersForm.value.statusFilters?.join() },
      queryParamsHandling: 'merge'
    })
  }

  private setImageUrl(persona: Persona): string {
    const profileImages = persona.profile_images
    const imageUrl = (profileImages || []).find(i => i.image_type === 'crop')?.url
    return imageUrl
  }

  private onSort(data: any): void {
    if (!data.direction) {
      this.dataSource.data = this.credentialListItemsService.sortStatus(this.dataSource.data as any)
    } else if (data.column === 'credential') {
      this.sort(['name'], data)
    } else if (data.column === 'status') {
      this.sort(['chip', 'text'], data)
    } else if (data.column === 'validityDates') {
      this.sort(['validityDates'], data)
    } else if (data.column === 'person') {
      this.sort(['personaName'], data)
    }
  }

  private sort(fields: string[], data: any): void {
    const isAsc = data.direction === 'asc'
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const firstValue = fields.reduce((acc, cur) => acc[cur], a)
      const secondValue = fields.reduce((acc, cur) => acc[cur], b)
      const { first, second } = this.checkForDates(firstValue, secondValue)
      if (first > second) return isAsc ? 1 : -1
      if (first < second) return isAsc ? -1 : 1
      return 0
    })
  }

  private checkForDates(firstValue: string, secondValue: string): any {
    const first = DateTime.fromFormat(firstValue.split('-')[0].trim(), 'MMM d, yyyy')
    const second = DateTime.fromFormat(secondValue.split('-')[0].trim(), 'MMM d, yyyy')
    if (first.isValid && second.isValid) {
      return { first, second }
    }
    return { first: firstValue, second: secondValue }
  }

  private async checkQueryParams(): Promise<void> {
    const params = window.location.href.split('?')
    const urlParams = new URLSearchParams(params[1])
    const personaId = urlParams.get('personaId')
    this.organizationId = +urlParams.get('orgId')
    const personFilters = urlParams.get('personFilters')
    const statusFilters = urlParams.get('statusFilters')
    if (!this.organizationId) return

    if (personaId) {
      window.history.pushState({}, document.title, window.location.href.split('?')[0])
      this.filtersForm.get('personFilters').get(personaId).setValue(true)
    }
    if (personFilters) {
      personFilters.split(',').forEach((id) => this.filtersForm.get('personFilters').get(id).setValue(true))
    }
    if (statusFilters) {
      this.filtersForm.get('statusFilters').setValue(statusFilters.split(','))
    }
    await this.loadTable()
  }

  private getLinkedEligibilityRuleSetIds(personaEligibilities: PersonaEligibility[]): string[] {
    return personaEligibilities.reduce((acc, pe: any) => {
      const ruleSetIds = pe.eligibility_rule_set.eligibility_rules
        .filter((er: any) => er.linked_eligibility_rule_set_id && er.originator_type === 'membership_definition')
        .map((er: any) => er.linked_eligibility_rule_set_id)
      acc = [...acc, ...ruleSetIds]
      return acc
    }, [])
  }

}
