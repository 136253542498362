import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { RsvpView } from '../../view-models/rsvp-view'
import { EventCard } from './event-card-types'
import { EventTimeRange } from './event-time-range'

enum RsvpState {
  Yes = 'yes',
  Maybe = 'maybe',
  No = 'no',
  NoResponse = 'noResponse'
}

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {

  @Input('dataEvent') public event: EventCard

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onRsvp = new EventEmitter()

  public range: string
  public titleLinkType: string
  public fallbackTeamColor = '#00668f'
  public cardAccentColor = ''
  public rsvps: RsvpView[] = []
  public rsvpListOpen: boolean
  public rsvpAccordionTitle: string
  public shownAvatars: any[]
  public showCount: boolean
  public avatarsNotShown: string
  private eventTimeRange = new EventTimeRange()

  constructor() {
    //noop
  }

  public ngOnInit(): void {
    this.cardAccentColor = this.getTeamColor()
    this.range = this.eventTimeRange.getTimeRange(this.event)
    this.rsvps = this.getRsvps()
    this.shownAvatars = this.getShownAvatars()
    this.rsvpListOpen = !this.hasAllRsvps()
    this.setRSVPAccordionHeader()
  }

  public getTeamColor(): string {

    if (!this.event.teamColor) {
      return this.fallbackTeamColor
    }

    return this.event.teamColor
  }

  public setRSVPAccordionHeader(): void {
    this.rsvpAccordionTitle = this.rsvpListOpen ? 'Are You Going?' : 'RSVPs'
  }

  public hasAllRsvps(): boolean {
    if (this.rsvps && this.rsvps.length > 0) {
      return this.rsvps.every((rsvp) => rsvp.response && this.rsvpHasResponse(rsvp.response))
    } else {
      return false
    }
  }

  public toggleAccordion(): void {
    this.rsvpListOpen = !this.rsvpListOpen
    this.setRSVPAccordionHeader()
  }

  public emitEvent(event): void {
    this.onRsvp.emit(event)
  }

  public getShownAvatars(): any[] {
    let shown = []
    this.rsvps.forEach(val => shown.push(Object.assign({}, val)))

    if (shown.length > 4) {
      this.showCount = true
      shown = shown.slice(0, 3)
      this.avatarsNotShown = '+' + (this.rsvps.length - 3).toString() // create the number for the general avatar
    }
    return shown
  }

  private getRsvps(): RsvpView[] {
    const rsvpIds = []
    const rsvps = []
    this.event.rsvps.forEach(rsvp => {
      if (!rsvpIds.includes(rsvp.id)) {
        rsvps.push(rsvp)
        rsvpIds.push(rsvp.id)
      }
    })
    return rsvps
  }

  private rsvpHasResponse(response: string): boolean {
    const lower = response.toLowerCase()
    return (lower === RsvpState.Yes || lower === RsvpState.Maybe || lower === RsvpState.No)
  }

}
