import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'

import { PersonaEligibility } from 'se-resource-types/dist/lib/EligibilitySearchService'

@Injectable({
  providedIn: 'root'
})
export class EligibilityService {
  public endpoint = 'v3/eligibility'

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public findAll(persona_ids: (string | number)[], per_page=25, params={}): Observable<PersonaEligibility[]> {
    const url = `${this.endpoint}/filter`
    const body = {
      hydrate: {
        'EligibilityRuleSet.boss_organization': ['id', 'name', 'org_logo', 'timezone'],
        'PersonaEligibility.eligibility_rule_set': ['id', 'name', 'boss_organization', 'eligibility_rules'],
        'PersonaEligibilityCredential.credential_set_organization': ['id', 'name', 'org_logo', 'timezone'],
        'PersonaEligibilityCredential.linked_eligibility_rule_set': ['id', 'name', 'boss_organization', 'eligibility_rules']
      },
      per_page,
      persona_ids
    }
    return this.api.post<PersonaEligibility[]>(url, body, { params }).pipe(
      map(response => response.result)
    )
  }

  public getCredentialCategories(organizationId: number): Observable<any> {
    return this.api.get(`${this.endpoint}/credential_categories/${organizationId}`)
  }

  public findCredentials(options: any, params={}): Observable<any> {
    const url = `${this.endpoint}/credentials/filter`
    const body = {
      per_page: 1000,
      status: ['complete'],
      ...options
    }
    return this.api.post(url, body, { params }).pipe(
      map(response => response.result)
    )
  }

}
