import { TitleCasePipe } from '@angular/common'
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { ChildGrantAccessModalComponent } from '@se-po/se-my-sportsengine-feature-child-grant-access-modal'
import { ParentGrantAccessModalComponent } from '@se-po/se-my-sportsengine-feature-parent-grant-access-modal'
import {
  AnalyticsService,
  HouseholdGuardianInvitesService,
  HouseholdGuardianshipsService,
  HouseholdPersonasService,
  HouseholdUsersService,
  UserService
} from '@se-po/shared-data-access-services'
import { Observable, forkJoin, tap } from 'rxjs'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import {
  PersonaAccount,
  PersonaGuardianInvite,
  RelatedGuardiansResult,
  UserProfile
} from 'se-resource-types/dist/lib/CentralService/Households'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'
import { User } from 'se-resource-types/lib/UserService'

@Component({
  selector: 'se-po-permissions-card',
  templateUrl: './permissions-card.component.html',
  styleUrls: ['./permissions-card.component.scss'],
})
export class PermissionsCardComponent {
  @Input() profiles: UserProfile[]
  @Input() isGuardian: boolean
  @Input() isSelf: boolean
  @Output() public guardianUpdate = new EventEmitter<void>()
  @ViewChild('removeConfirmModal') public removeConfirmModal: SeFeModalComponent
  @ViewChild('removeConfirmFooter') public removeConfirmFooter: SeFeModalFooterComponent
  @ViewChild('addChildPermissionsModal') public addChildPermissionsModal: ChildGrantAccessModalComponent
  @ViewChild('addParentPermissionsModal') public addParentPermissionsModal: ParentGrantAccessModalComponent


  public accountItems: any[]
  public accountItemsReady = false
  public checkboxOptions = {}
  public checkboxValues = {}
  public currentUser: User
  public errors = []
  public form: UntypedFormGroup
  public formReady = false
  public hasMetGuardianLimit = false
  public initialValues = {}
  public inviteItems: any[]
  public allInvites: any[] = []
  public inviteItemsReady = false
  public guardianToRemove = null
  public accessRemoveSubject = null

  public removeConfirmButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.removeConfirmModal.close()
      },
    },
    {
      primary: true,
      destructive: true,
      label: 'Yes, Remove',
      action: () => {
        this.removeAccount(this.guardianToRemove)
      },
    },
  ]

  private _profile: any
  private _guardians: RelatedGuardiansResult[]

  constructor(
    public householdGuardianInvitesService: HouseholdGuardianInvitesService,
    public householdGuardianshipsService: HouseholdGuardianshipsService,
    public householdPersonasService: HouseholdPersonasService,
    public householdUsersService: HouseholdUsersService,
    public titleCasePipe: TitleCasePipe,
    public toastService: SeFeToastService,
    public userService: UserService,
    private analyticsService: AnalyticsService
  ) {
    this.sortByOwnerAndUser = this.sortByOwnerAndUser.bind(this)
  }

  public get canManageProfile(): boolean {
    return ['manager', 'owner'].includes(this.profile?.access)
  }

  public get profile(): any {
    return this._profile
  }

  public get guardians(): RelatedGuardiansResult[] {
    return this._guardians
  }

  public get ready(): boolean {
    return this.accountItemsReady && this.inviteItemsReady
  }

  public get useAddParentModal(): boolean {
    return (this.accountItems?.length || this.inviteItems?.length) && this.isGuardian && this.ready
  }

  public get useAddChildModal(): boolean {
    return !(this.isGuardian || this.isSelf) && this.ready
  }

  @Input() public set profile(profile: any) {
    this._profile = profile
    if (profile) {
      this.setAccountData()
    }
  }

  @Input() public set guardians(guardians: RelatedGuardiansResult[]){
    this._guardians = guardians
    if (this.profile && guardians) {
      this.setAccountData()
    }
  }

  public setAccountData(): void {
    this.accountItemsReady = false
    this.inviteItemsReady = false
    this.getUser()
  }

  public getUser(): void {
    this.userService.mine().subscribe(
      (result) => {
        this.currentUser = result
        this.createAccountListItems()
        if(!this.isSelf) {
          this.createInviteListItems()
        } else {
          this.inviteItems = []
          this.inviteItemsReady = true
        }
      })
  }

  public createAccountListItems(): any {
    if (this.isGuardian || this.isSelf) {
      this.createChildListItems()
    } else {
      this.createGuardiansListItems()
    }
  }

  public createChildListItems(): void {
    const childProfiles = this.isSelf ? this.profiles.filter(prof => prof.access !== 'owner') : this.profile.profiles
    this.accountItems = childProfiles.map((profile: UserProfile) => {
      const currentUserAccess = this.profiles.find(prof => prof.persona.id === profile.persona.id).access
      return {
          access: this.titleCasePipe.transform(profile.access),
          id: profile.id,
          imgUrl: (profile.persona.profile_images || []).find(p => p.image_type === 'crop_icon')?.url,
          subtitle: this.createChildItemSubtitle(profile.persona),
          title: profile.persona?.full_name,
          pending: false,
          personaId: profile.persona?.id,
          menuOptions: {
            name: `account-${profile.id}`,
            sections: [
              {
                menuItems: [
                  {
                    text: 'Remove Access',
                    disabled: false,
                    action: () => this.openRemoveModal(profile)
                  }
                ],
              }
            ]
          },
          ...currentUserAccess === ('owner' || 'manager') && {
            accessOptions: {
            name: `access-${profile.id}`,
            sections: [
              {
                menuItems: [
                  {
                    text: 'Manager',
                    disabled: false,
                    action: () => this.editAccess(profile, 'manager', false)
                  },
                  {
                    text: 'Viewer',
                    disabled: false,
                    action: () => this.editAccess(profile, 'viewer', false)
                  }
                ],
              }
            ]
          }
          }
        }
      }
    )
    this.accountItemsReady = true
  }

  public createChildItemSubtitle(persona: Persona): string[] {
    const dob = this.formatDate(persona.date_of_birth)
    let gradYearString = ''
    if(!!persona.graduation_year) {
      const gradString = parseInt(persona.graduation_year, 10) < new Date().getFullYear() ? 'Graduated' : 'Graduates'
      gradYearString = `${gradString} ${persona.graduation_year}`
    }
    return [dob, gradYearString, this.titleCasePipe.transform(persona.gender)].filter(item => !!item)
  }

  public createGuardiansListItems(): void {
    this.householdPersonasService.findPersonaAccountsResponse(this.profile.persona.id).subscribe(response => {
      const accounts = response.result.accounts
      accounts.sort(this.sortByOwnerAndUser)
      this.accountItems = accounts.map((account: PersonaAccount) => (
        {
          access: account.access === 'owner' ? 'Manager' : this.titleCasePipe.transform(account.access),
          id: account.id,
          imgUrl: (account.user?.self_persona.profile_images || []).find(p => p.image_type === 'crop_icon')?.url,
          email: account.user?.email_address.address,
          personaId: account.user?.self_persona?.id,

          ...this.canRemoveAccount(account) && {
            menuOptions: {
              name: `account-${account.id}`,
              sections: [
                {
                  menuItems: [
                    {
                      text: account.user.id === this.currentUser.id ? 'Remove Your Access' : 'Remove Access',
                      disabled: false,
                      action: () => this.openRemoveModal(account)
                    }
                  ],
                }
              ]
            }
          },
          ...(this.canManageProfile && account.access !== 'owner') && {
            accessOptions: {
              name: `access-${account.id}`,
              sections: [
                {
                  menuItems: [
                    {
                      text: 'Manager',
                      disabled: false,
                      action: () => this.editAccess(account, 'manager')
                    },
                    {
                      text: 'Viewer',
                      disabled: false,
                      action: () => this.editAccess(account, 'viewer')
                    }
                  ],
                }
              ]
            }
          },
          subtitle: account.access === 'owner' ? 'Account Owner' : account.user?.email_address?.address,
          title: account.user?.self_persona?.full_name,
          pending: false
        }
      ))
      this.checkGuardianLimit()
      this.accountItemsReady = true
    })
  }

  public checkGuardianLimit(): void {
    if (((this.accountItems?.length || 0) + (this.inviteItems?.length || 0)) > 9) this.hasMetGuardianLimit = true
    else this.hasMetGuardianLimit = false
  }

  public createInviteListItems(): void {
    this.inviteItems = []
    if (this.isGuardian) {
      const inviteRequests = this.profiles.map(prof => this.getInvitesForProfile(prof))
      forkJoin(inviteRequests).subscribe(() => {
        this.allInvites = this.inviteItems
        this.inviteItems = this.inviteItems.filter(item => item.email === this.profile.user.email_address.address)
        this.inviteItemsReady = true
      })
    } else {
      this.getInvitesForProfile(this.profile).subscribe(() => {
        this.checkGuardianLimit()
        this.inviteItemsReady = true
      })
    }
  }

  public getInvitesForProfile(profile: UserProfile): Observable<any> {
    return this.householdPersonasService.findPersonaGuardianInvites(profile.persona.id).pipe(
      tap(invites => {
        this.inviteItems = this.inviteItems.concat(
          invites.map((invite: PersonaGuardianInvite) => ({
            access: this.titleCasePipe.transform(invite.access),
            id: invite.id,
            email: invite.email_address,
            ...(profile.access !== 'viewer' && {
              menuOptions: {
                name: `invite-${invite.id}`,
                sections: [
                  {
                    menuItems: [
                      {
                        text: 'Resend',
                        disabled: false,
                        action: () => this.resendInvite(invite)
                      },
                      {
                        text: 'Cancel',
                        disabled: false,
                        action: () => this.removeInvite(invite)
                      }
                    ]
                  }
                ]
              }
            }),
            subtitle: this.isGuardian ? this.createChildItemSubtitle(profile.persona) : invite.email_address,
            title: this.createInviteTitle(profile.persona, invite),
            pending: true,
            persona: profile.persona
          }))
        )
      })
    )
  }

  public createInviteTitle(persona: Persona, invite: PersonaGuardianInvite): string {
    if (this.isGuardian) {
      return persona.first_name + ' ' + persona.last_name
    }
    return invite.user?.self_persona?.name || invite.email_address
  }

  public sortByOwnerAndUser(a: PersonaAccount, b: PersonaAccount): number {
    const ownerA = a.access === 'owner'
    const ownerB = b.access === 'owner'
    if (ownerA && !ownerB) return -1
    if (ownerB && !ownerA) return 1

    const currentUserA = a.user.id === this.currentUser?.id
    const currentUserB = b.user.id === this.currentUser?.id
    if (currentUserA && !currentUserB) return -1
    if (currentUserB && !currentUserA) return 1

    return 0
  }

  public canRemoveAccount(account: PersonaAccount): boolean {
    // You cannot remove an account from a profile if they own it or it is their self profile
    // The current user also must have manage or greater access unless it is their own account
    const isAccount = account.user.id === this.currentUser?.id
    return account.relationship !== 'self' && account.access !== 'owner' && (this.canManageProfile || isAccount)
  }

  public editAccess(account: any, access: string, profileIsChild = true): void {
    const guardianFirstName = profileIsChild ? account.user.self_persona.first_name : this.profile.user.self_persona.first_name
    const childFirstName =  profileIsChild ? this.profile.persona.first_name : account.persona.first_name
    const guardianshipId = profileIsChild ? account.persona_listener_id : account.guardianship_id
    const message = `${guardianFirstName}'s access has been successfully changed to ${access} for ${childFirstName}'s profile`
    this.householdGuardianshipsService.update(guardianshipId, access).subscribe(res => {
      const listItem = this.accountItems.find(item => item.id === account.id)
      listItem.access = this.titleCasePipe.transform(res.access)
      this.guardianUpdate.emit()
      this.toastService.success(message)
    })
  }

  public openAddModal(): void {
    if (this.useAddParentModal) {
      this.addParentPermissionsModal.open()
    }
    else {
      this.addChildPermissionsModal.open()
    }
    this.addPermissions()
  }

  public disableGrant(): boolean {
    if(this.addParentPermissionsModal?.enabledAddableChildren.length === 0 || this.hasMetGuardianLimit) return true
    else return false
  }

  public openRemoveModal(account: any): void {
    this.guardianToRemove = account.user ? account : this.profile
    this.accessRemoveSubject = account.user ? this.profile : account
    this.removeConfirmModal.open(null)
  }

  public removeAccount(account: any): void {
    const guardianFirstName = this.guardianToRemove.user ?
      this.guardianToRemove.user.self_persona.first_name : this.guardianToRemove.persona.first_name
    const childFirstName = this.accessRemoveSubject.user ?
      this.accessRemoveSubject.user.self_persona.first_name : this.accessRemoveSubject.persona.first_name
    const message = `${guardianFirstName} has been removed as a guardian from ${childFirstName}'s profile`
    const removeId = this.isGuardian ? this.accessRemoveSubject.guardianship_id : this.guardianToRemove.persona_listener_id
    this.removeGuardian(removeId).subscribe(res => {
      this.accountItems = this.accountItems.filter(item => item.id !== account.id)
      this.guardianUpdate.emit()
      this.removeConfirmModal.close()
      this.toastService.success(message)
    })
  }

  public removeInvite(invite: PersonaGuardianInvite): void {
    const name = invite.user?.self_persona?.name ? invite.user?.self_persona?.name : invite.email_address
    const message = `Invitation has been canceled for ${name}`
    this.removePendingGuardian(invite.id).subscribe(res => {
      this.inviteItems = this.inviteItems.filter(item => item.id !== invite.id)
      this.toastService.success(message)
    })
  }

  public resendInvite(invite: PersonaGuardianInvite) {
    const name = invite.user?.self_persona?.name ? invite.user?.self_persona?.name : invite.email_address
    const message = `Invitation has been re-sent to ${name} successfully.`
    this.householdGuardianInvitesService.resend(invite.id).subscribe(res => {
      this.toastService.success(message)
    })
  }

  public removeGuardian(id: number): Observable<any> {
    return this.householdGuardianshipsService.remove(id)
  }

  public removePendingGuardian(id: number | string): Observable<any> {
    return this.householdGuardianInvitesService.cancel(id)
  }

  public guardianAdded(): void {
    this.createInviteListItems()
  }

  public addPermissions(): void {
    this.analyticsService.seEvent('HouseholdSidePanel.PermissionsCard.AddClick', 8)
  }

  private formatDate(dateString): string {
    if (!dateString) return null
    const splitDate = dateString.split('-')
    const months = {
      '01': 'Jan',
      '02': 'Feb',
      '03': 'Mar',
      '04': 'Apr',
      '05': 'May',
      '06': 'Jun',
      '07': 'Jul',
      '08': 'Aug',
      '09': 'Sept',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec'
    }
    const month = months[splitDate[1]]
    const day = splitDate[2]
    const year = splitDate[0]

    return `${month} ${day}, ${year}`
  }
}
