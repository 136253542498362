<se-fe-page
  seFeDataWidth="full"
  [seFeDataLoaded]="ready">
  <se-fe-header seFePageHeader
    seFeDataSize="750"
    seFeDataTitle="Household">
  </se-fe-header>
  <div seFePageContent>
    <div class="household-avatars" *ngIf="ready">
      <se-po-duplicate-banner [profiles]="profiles" (merge)="onMerge($event, 'from_banner')">
      </se-po-duplicate-banner>
      <ng-container *ngIf="showGuardians; else immediateHousehold">
        <div *ngIf="selfProfile" class="household-avatar">
          <se-po-household-avatar
            seFeSidePanelTrigger
            [profile]="selfProfile"
            (chosen)="onChosen($event)"
            (remove)="onRemove($event)">
          </se-po-household-avatar>
        </div>
        <div *ngIf="childProfiles.length" class="household-subheading">
          Children
          <button type="button" seFePopoverTrigger="childrenHouseholdPopover" class="household-popover">
            <se-fe-icon
              seFeDataName="info_circle"
              seFeDataSize="500"
              seFeDataColor="gray-700">
            </se-fe-icon>
          </button>
          <se-fe-popover seFeDataName="childrenHouseholdPopover">
            Profiles under the age of 18.
          </se-fe-popover>
        </div>
        <div *ngFor="let profile of childProfiles" class="household-avatar">
          <se-po-household-avatar
            seFeSidePanelTrigger
            [profile]="profile"
            [profiles]="childProfiles"
            (chosen)="onChosen($event)"
            (merge)="onMerge($event)"
            (remove)="onRemove($event)">
          </se-po-household-avatar>
        </div>
        <div *ngIf="adultProfiles.length || managerGuardians.length" class="household-subheading">
          Adults
          <button type="button" seFePopoverTrigger="adultsHouseholdPopover" class="household-popover">
            <se-fe-icon
              seFeDataName="info_circle"
              seFeDataSize="500"
              seFeDataColor="gray-700">
            </se-fe-icon>
          </button>
          <se-fe-popover seFeDataName="adultsHouseholdPopover">
            Profiles over the age of 18.
          </se-fe-popover>
        </div>
        <div *ngFor="let profile of adultProfiles" class="household-avatar">
          <se-po-household-avatar
            seFeSidePanelTrigger
            [profile]="profile"
            (chosen)="onChosen($event)"
            (remove)="onRemove($event)">
          </se-po-household-avatar>
        </div>
        <div *ngFor="let guardian of managerGuardians" class="household-avatar">
          <se-po-household-avatar
            seFeSidePanelTrigger
            [profile]="guardian"
            (chosen)="onChosen($event)"
            (remove)="onRemove($event)">
          </se-po-household-avatar>
        </div>
      </ng-container>
      <ng-template #immediateHousehold>
        <div *ngIf="selfProfile" class="household-avatar">
          <se-po-household-avatar
            seFeSidePanelTrigger
            [profile]="selfProfile"
            (chosen)="onChosen($event)"
            (remove)="onRemove($event)">
          </se-po-household-avatar>
        </div>
        <div *ngFor="let profile of childProfiles" class="household-avatar">
          <se-po-household-avatar
            seFeSidePanelTrigger
            [profile]="profile"
            [profiles]="childProfiles"
            (chosen)="onChosen($event)"
            (merge)="onMerge($event)"
            (remove)="onRemove($event)">
          </se-po-household-avatar>
        </div>
      </ng-template>
    </div>
    <se-po-household-side-panel #householdSidePanel
      [(personaId)]="selectedPersonaId"
      [guardians]="allGuardians"
      [profiles]="profiles"
      (edit)="onEdit($event)"
      (guardianUpdate)="onGuardianUpdate()"
      (merge)="onMerge($event, 'from_modal')"
      (personaEdited)="onPersonaEdit($event)"
      (profileRemove)="onRemove($event)">
    </se-po-household-side-panel>
  </div>
</se-fe-page>
<se-po-edit-persona-modal #editPersonaModal
  seFeSidePanelTrigger
  (personaEdited)="onPersonaEdit($event)">
</se-po-edit-persona-modal>
<se-po-remove-persona-modal #removePersonaModal
  seFeSidePanelTrigger
  (personaRemoved)="onPersonaRemove($event)">
</se-po-remove-persona-modal>
<se-po-merge-persona-modal #mergePersonaModal
  seFeSidePanelTrigger
  (personaMerged)="onPersonaRemove($event)">
</se-po-merge-persona-modal>
