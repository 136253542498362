import { ClareResources as cr } from 'se-resource-types'

export class SalePayment implements cr.SalePayment {
  public acct_num: string
  public acct_type: string
  public acct_type_display: string
  public amount: string
  public amount_adjusted: string
  public amount_refunded: string
  public can_be_refunded: boolean
  public chargeback: boolean
  public created_at: string
  public disputed: boolean
  public due_date: string
  public due_date_days_past: number
  public echeck: boolean
  public editable: boolean
  public fully_refunded: boolean
  public id: number
  public is_paid: boolean
  public label: string
  public memo: string
  public name: string
  public non_refundable_reason: string
  public original_amount: string
  public paid_at: string
  public position: number
  public sale_id: number
  public sale_number: string
  public sale_transaction_id: number
  public status: 'canceled' | 'chargeback' | 'disputed' | 'paid' | 'paid_offline' | 'unpaid'
  public status_display: string
  public tender: string
  public updated_at: string
  public upfront_payment: boolean
  public user_id: number
  public uuid: string
  public zero_amount: boolean

  public constructor(data?: any) {
    Object.assign(this, data || {})
  }

  public get canceled(): boolean {
    return this.status === 'canceled'
  }
  public get pastDue(): boolean {
    return this.due_date_days_past && this.due_date_days_past > 0
  }
  public get unpaid(): boolean {
    return this.status === 'unpaid'
  }

  public get originalAmountStrikethrough(): boolean {
    return Number(this.original_amount) > 0 && this.amount !== this.original_amount
  }
}

export class SaleActivity implements cr.SaleActivity {
  public amount: string
  public amount_outstanding: string
  public created_at: string
  public label: string
  public memo?: string
  public notes?: string
  public sale_transaction_id?: number
  public significance: string

  public constructor(data?: any) {
    Object.assign(this, data || {})
  }
}

export class SaleItem implements cr.SaleItem {
  public id: number
  public sale_id: number
  public name: string
  public quantity: number
  public price: string
  public price_cents: number
  public amount: string
  public amount_cents: number
  public upfront_amount: string
  public shipped_at: string
  public created_at: string
  public item_variation_id: number
  public sale_number: string
  public paid_on: string
  public store_id: number
  public is_paid: boolean
  public full_name: string
  public item_uuid: string
  public item_variation_uuid: string
  public use_flat_fee: boolean
  public fees_total: string
  public currency: string
  public prior_amount: string

  public constructor(data?: any) {
    Object.assign(this, data || {})
  }
}

export class SaleRegister implements cr.SaleRegister {
  public amount: string
  public description: string
  public created_at: string
  public line_level: string
  public register_type: string
  public price: string
  public quantity: number
  public updated_at: string

  public constructor(data?: any) {
    Object.assign(this, data || {})
  }
}
export class Sale implements cr.Sale {
  private static PAYMENT_ACCOUNT_TYPES: any = {
    visa : 'Visa',
    mastercard : 'MasterCard',
    discover : 'Discover',
    american_express : 'American Express',
    checking : 'Checking',
    savings : 'Savings',
    check : 'Offline Check',
    cash : 'Offline Cash',
    complimentary : 'Complimentary'
  }

  public id: number
  public amount: number
  public amount_outstanding: string
  public amount_paid: string
  public amount_refunded: string
  public amount_charged_back: string
  public amount_adjusted: string
  public amount_credited: string
  public can_comp: boolean
  public can_void: boolean
  public cart_id: number
  public created_at: string
  public description: string
  public email_address: string
  public org_id: number
  public organization_id: number
  public payment_plan_id: number
  public payment_plan_payment_method: any
  public placed_at: string
  public placed_successfully: boolean
  public sale_number: string
  public salesperson_id: number
  public salesperson_full_name: string
  public display_status: string
  public store_id: number
  public store_name: string
  public total: string
  public total_unadjusted: string
  public type: string
  public updated_at: string
  public user_full_name: string
  public user_id: number
  public persona_id: number
  public currency_symbol: string
  public itemized: boolean
  public reminded_at: string
  public amount_discounted: string
  public discounted: boolean
  public refunded: boolean
  public credited: boolean
  public taxable: boolean
  public items: SaleItem[]
  public payments: cr.SalePayment[]
  public refunds: cr.SaleRefund[]
  public transactions: cr.SaleTransaction[]
  public sale_sources: cr.SaleSource[]
  public registers: SaleRegister[]
  public billing_address: cr.SaleAddress
  public shipping_address: cr.SaleAddress
  public metadata: any
  public due_date: string
  public invoice_group_id: number
  public invoice_group_number: string
  public monetary_status: 'credit' | 'paid' | 'partial-paid' | 'partial-refund' | 'past-due' | 'refunded' | 'unpaid' | 'void'
  public status: 'pending' | 'settled' | 'unsettled' | 'voided'
  public customer_pays_fee: boolean

  public constructor(data?: any) {
    Object.assign(this, data || {})
  }

  public get payment_plan_account_type(): string {
    if (this.payment_plan_payment_method) {
      return Sale.PAYMENT_ACCOUNT_TYPES[this.payment_plan_payment_method.acct_type]
    }
  }


}
