<se-fe-header
  *ngIf="ready"
  class="persona-avatar"
  [seFeDataTitle]="!headerLink && title"
  [seFeDataSubtitle]="subtitle"
  [seFeDataLevel]="0"
  [seFeDataSize]="headerDataSize">
  <se-fe-avatar
    seFeHeaderVisual
    seFeDataType="persona"
    [seFeDataIdentity]="title"
    [seFeDataImgUrl]="imageUrl"
    [seFeDataSize]="avatarDataSize">
  </se-fe-avatar>
  <a seFeHeaderTitle *ngIf="headerLink" class="persona-avatar-link" [href]="headerLink">{{ title }}</a>
  <ng-content seFeHeaderActions select="[personaAvatarActions]"></ng-content>
</se-fe-header>
