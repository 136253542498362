import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { environment } from '@se-po/shared-environments'
import { SpinnerService } from '../../services/spinner.service'

@Component({
  selector: 'app-ngin-iframe',
  templateUrl: './ngin-iframe.component.html',
  styleUrls: ['./ngin-iframe.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { class: 'pl-iframe__wrapper' }
})
export class NginIframeComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public path: string
  @Input() public domain: string
  public url: SafeResourceUrl

  constructor(private window: Window,
    private spinnerService: SpinnerService,
    private sanitizer: DomSanitizer,
    public route: ActivatedRoute) {
  }

  @HostListener('window:message', ['$event.data'])
  public onPostMessage(message: any) {
    const data = message || {}
    switch (data.type) {
      case 'userIframe:loading':
        this.spinnerService.start()
        break
      case 'userIframe:loaded':
        this.spinnerService.stop()
        break
      case 'message:viewed':
        this.updateNginBarMessagesMenu(data.id)
        break
      default:
        break
    }
  }

  public ngOnInit(): void {
    this.spinnerService.start()
  }

  public ngOnChanges(): void {
    this.url = this.buildFrameUrl()
  }

  public ngOnDestroy(): void {
    this.spinnerService.stop()
  }

  private updateNginBarMessagesMenu(messageId): void {
    if (messageId && (this.window as any).nginBarV2) {
      (this.window as any).nginBarV2.markMessageAsRead(messageId)
    }
  }

  private buildFrameUrl(): SafeResourceUrl {
    const domain = this.domain || environment.nginUserDashboard
    let builtPath: string = this.route.snapshot.queryParams.url || this.path || ''
    if (builtPath.startsWith('/')) {
      builtPath = builtPath.substring(1)
    }

    // subsitute custom route params
    const paramSubRegex = /:([a-zA-Z0-9_]+)/g
    builtPath = builtPath.replace(paramSubRegex, (match, param) => this.route.snapshot.queryParams[param] || match)

    // split so we can ensure the search hash is at the end for paths like /foo/bar#anchor
    const parts = builtPath.split('#')
    const hash = parts[1] ? '#' + parts[1] : ''
    let url = domain + parts[0]
    url += url.indexOf('?') === -1 ? '?' : '&'
    url += 'layout=pl' + hash
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)

  }
}
