<div class="pl-pane__container--horizontal">
  <header class="pl-page-header pl-pane--shrink">
    <div pl-grid="spaced tight align-middle">
      <div pl-grid-el pl-grid="1/2@phone">
        <h1 class="pl-heading--xlarge" [textContent]="'USER.bills' | translate"></h1>
      </div>
    </div>
  </header>

  <section class="pl-pane__info">
    <div class="pl-centered-container--auto">
      <se-fe-card *ngIf="!loading">
        <se-fe-card-content>
          <div *ngIf="bills?.length || searchTerm"
            class="pl-pane--shrink pl-pane--condense-bottom">
            <div pl-grid="tight fixed">
              <div pl-grid-el pl-grid="1/1@phone 1/2@tablet 1/3@desktop">
                <label class="pl-search-field">
                  <input
                    id="registration_search"
                    name="registration_search"
                    [(ngModel)]="searchTerm"
                    (ngModelChange)="search($event)"
                    placeholder="{{ 'BILLS_LIST.LABELS.search_placeholder' | translate }}"
                    class="pl-search-field__input"
                    attr.aria-label="{{ 'BILLS_LIST.LABELS.search_placeholder' | translate }}" />
                </label>
              </div>
            </div>
          </div>

          <div
            class="pl-pane--condense-top">
            <div class="pl-data-table__container">
              <table *ngIf="bills?.length" class="pl-data-table" id="bills-table">
                <thead>
                  <tr>
                    <th style="min-width: 80px;"
                      [app-sort-column]="'sale_number'" [(currentColumn)]="sortedColumn" >
                      {{ 'BILLS_LIST.LABELS.sale_number' | translate }}
                    </th>
                    <th style="min-width: 150px;"
                      [app-sort-column]="'description'" [(currentColumn)]="sortedColumn">
                      {{ 'BILLS_LIST.LABELS.description' | translate }}
                    </th>
                    <th [textContent]="'BILLS_LIST.LABELS.recipient' | translate"></th>
                    <th style="min-width: 120px;"
                      [app-sort-column]="'due_date'" [(currentColumn)]="sortedColumn">
                      {{ 'BILLS_LIST.LABELS.payment_due' | translate }}
                    </th>
                    <th class="pl-text--right"
                      [textContent]="'BILLS_LIST.LABELS.billed' | translate">
                    </th>
                    <th style="min-width: 100px;" class="pl-text--right"
                      [textContent]="'BILLS_LIST.LABELS.paid' | translate">
                    </th>
                    <th *ngIf="displayCredits" style="min-width: 100px;" class="pl-text--right"
                      [textContent]="'BILLS_LIST.LABELS.discounted' | translate">
                    </th>
                    <th *ngIf="displayDiscounts" style="min-width: 100px;" class="pl-text--right"
                      [textContent]="'BILLS_LIST.LABELS.credited' | translate">
                    </th>
                    <th class="pl-text--right"
                      [textContent]="'BILLS_LIST.LABELS.outstanding' | translate">
                    </th>
                    <th style="min-width: 150px;"
                      [app-sort-column]="'display_priority'" [(currentColumn)]="sortedColumn">
                      {{ 'BILLS_LIST.LABELS.payment_status' | translate }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let bill of bills">
                    <td>
                      <a
                        [routerLink]="['/user/bills', bill.sale_number]"
                        [textContent]="bill.sale_number"
                        class="pl-link">
                      </a>
                    </td>
                    <td [textContent]="bill.description"></td>
                    <td [textContent]="bill.billing_address.name"></td>
                    <td>
                      <se-fe-icon
                        *ngIf="bill.display_status === 'past_due'"
                        seFeDataMargin="false"
                        seFeDataSize="500"
                        seFeDataName="warning"
                        seFeDataColor="alert">
                      </se-fe-icon>
                      {{ bill.due_date | date: 'MMM d, yyyy' }}
                    </td>
                    <td class="pl-text--right">
                      <span [textContent]="bill.total_unadjusted | currency"></span>
                    </td>
                    <td class="pl-text--right"
                      [textContent]="bill.amount_paid | currency">
                    </td>
                    <td *ngIf="displayDiscounts" class="pl-text--right"
                        [textContent]="bill.amount_discounted | currency">
                    </td>
                    <td *ngIf="displayCredits" class="pl-text--right"
                      [textContent]="bill.amount_credited | currency"></td>
                    <!-- TODO CURRENCY -->
                    <td class="pl-text--right"
                      [textContent]="bill.amount_outstanding | currency">
                    </td>
                    <td *ngIf="showMakePayment(bill)">
                      <a
                        (click)="makePayment(bill)"
                        [textContent]="'BILLS_LIST.payment_status_make_payment' | translate"
                        class="pl-link">
                      </a>
                    </td>
                    <td *ngIf="!showMakePayment(bill)"
                      [textContent]="'BILLS_LIST.payment_status_' + defineStatus(bill) | translate">
                    </td>
                  </tr>
                </tbody >
              </table>
            </div>

            <div *ngIf="!bills.length && !!searchTerm">
              <se-fe-empty-state seFeDataTitle="{{ 'BILLS_LIST.EMPTY.search_header' | translate }}">
                <se-fe-icon seFeEmptyStateIcon seFeDataName="search_thin" seFeDataSize="950" seFeDataColor="gray-900"></se-fe-icon>
                {{ 'BILLS_LIST.EMPTY.search_subheader' | translate }}
              </se-fe-empty-state>
            </div>

            <div *ngIf="!bills.length && !searchTerm">
              <se-fe-empty-state seFeDataTitle="{{ 'BILLS_LIST.EMPTY.no_bills_header' | translate }}">
                <se-fe-icon seFeEmptyStateIcon seFeDataName="invoice_thin" seFeDataSize="950" seFeDataColor="gray-900"></se-fe-icon>
                {{ 'BILLS_LIST.EMPTY.no_bills_message' | translate }}
              </se-fe-empty-state>
            </div>

            <div *ngIf="bills.length" class="pl-pane--shrink pl-pane--border-above pl-pane--condense-top pl-pane--collapse-bottom">
              <app-pagination
                [(settings)]="paginationSettings"
                [metadata]="pageMetadata?.pagination"
                [label]="'BILLS_LIST.pagination_label' | translate"></app-pagination>
            </div>
          </div>
        </se-fe-card-content>
      </se-fe-card>
    </div>
  </section>
</div>
