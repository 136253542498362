import { Component, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { AnalyticsService } from '@se-po/shared-data-access-services'
import { Subscription, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { SeFeToastService } from 'se-fe-toast'
import { SyncScheduleComponent } from '../modals/sync-schedule/sync-schedule.component'
import { Event, Persona } from '../models'
import { ModalData, ModalService, PersonaService, RsvpService } from '../services'
import { CalendarService } from '../services/calendar.service'
import { EventView, RsvpView } from '../view-models'

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit, OnDestroy {
  public loaded = false
  public days: { day: string; events: EventView[] }[]
  public pastEvents: Event[] = []
  public upcomingEvents: Event[]
  public events: Event[]

  private calendarSubscription: Subscription
  private personas: Persona[]

  constructor(
    private calendarService: CalendarService,
    private rsvpService: RsvpService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private toast: SeFeToastService,
    private personaService: PersonaService
  ) {
    // noop
  }

  public get isLastPage(): boolean {
    return this.calendarService.lastPage
  }

  public get isFirstPage(): boolean {
    return this.calendarService.earliestPage
  }

  public ngOnDestroy(): void {
    this.calendarSubscription.unsubscribe()
  }

  public ngOnInit(): void {
    this.calendarService.reset()
    this.calendarSubscription = combineLatest([this.calendarService.current, this.personaService.getGuarded()])
    .pipe(map((data: any[]) => ({ events: data[0] as Event[], personas: data[1] as Persona[] })))
    .subscribe(
      (data: { events: Event[]; personas: Persona[] }) => {
        if (data.events == null) return
        this.events = data.events
        this.personas = data.personas
        this.days = this.calendarService.initDays(this.events, this.personas)
        this.loaded = true
      }
    )
    this.calendarService.mine()
  }

  public eventClass(event: EventView): string {
    const idx = event.index.toString().replace('.', '-')
    return `js-event-index-${idx}` // support empty state 0.1 index
  }

  public onRsvp(rsvpChange: any): void {
    const newRsvp: RsvpView = rsvpChange.newValue
    const capitalizedEventType = `${newRsvp.eventType.charAt(0).toUpperCase()}${newRsvp.eventType.slice(1)}`
    const cardType = `${capitalizedEventType}Card`

    this.analyticsService.seEvent(`${cardType}.RSVP.${newRsvp.response}`, 8)

    this.rsvpService.setRsvp(newRsvp).subscribe({
      next: res => {
        this.toast.success(
          this.translateService.instant('SCHEDULE.rsvp_success', {
            identity: newRsvp.identity,
            response: newRsvp.response
          })
        )
      },
      error: err => this.toast.error(
        this.translateService.instant('SCHEDULE.rsvp_error')
      )
    })
  }

  public syncCalendar(): void {
    const data: ModalData = {
      component: SyncScheduleComponent,
      options: {
        title: this.translateService.instant('SCHEDULE_SYNC.title'),
        actions: [
          {
            action: () => this.modalService.close(),
            label: this.translateService.instant('SCHEDULE_SYNC.cancel'),
            cancel: true
          },
          {
            action: () => {
              this.modalService.component.onSubmit()
              this.modalService.close()
            },
            label: this.translateService.instant('SCHEDULE_SYNC.sync'),
            primary: true
          }
        ]
      }
    }
    this.modalService.open(data)
  }

  public previousPage(): void {
    this.loaded = false
    this.calendarService.previousPage()
  }

  public nextPage(): void {
    this.loaded = false
    this.calendarService.nextPage()
  }

  public firstDay(): string {
    return this.days[0]?.day
  }

  public lastDay(): string {
    return this.days[this.days.length - 1]?.day
  }
}
