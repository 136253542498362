<se-po-profile-side-panel
  [(showSidePanel)]="!!personaId"
  (avatarClicked)="onUploadImage()"
  [profile]="profile"
  [menuOptions]="menuOptions"
  [editableAvatar]="!isGuardian && profile && profile['access'] !== 'viewer'">
  <div *ngIf="profile && !loading && isGuardian"
    profileSidePanelContent>
    <se-po-permissions-card *ngIf="mySeVersion === '3'"
      [isSelf]="false"
      [profiles]="profiles"
      [guardians]="guardians"
      (guardianUpdate)="onGuardianUpdate()"
      [isGuardian]="true"
      [profile]="profile">
    </se-po-permissions-card>
  </div>
  <div *ngIf="profile && !loading && !isGuardian"
    profileSidePanelContent>
    <div class="side-panel-card">
      <se-po-household-contact-card
        (personaEdited)="onPersonaEdit()"
        [profile]="profile">
      </se-po-household-contact-card>
    </div>

    <!-- With the current guardian model, do not display permissions card for self profiles as we cannot assign access to them. -->
    <div class="side-panel-card">
      <se-po-guardian-permissions-card *ngIf="mySeVersion !== '3' && profile?.relationship !== 'self'"
        [profile]="profile"
        [profiles]="profiles">
      </se-po-guardian-permissions-card>

      <se-po-permissions-card *ngIf="mySeVersion === '3'"
        [isSelf]="isSelf"
        [profiles]="profiles"
        [guardians]="guardians"
        (guardianUpdate)="onGuardianUpdate()"
        [isGuardian]="isGuardian"
        [profile]="profile">
      </se-po-permissions-card>
    </div>

    <div class="side-panel-card">
      <se-po-overview-card
        [personaId]="profile?.persona?.id"
        [access]="profile?.access">
      </se-po-overview-card>
    </div>
  </div>
</se-po-profile-side-panel>
<se-po-persona-image-modal seFeSidePanelTrigger #personaImageModal
  (personaEdited)="onPersonaEdit()">
</se-po-persona-image-modal>
