import { Injectable } from '@angular/core'
import { environment } from '@se-po/shared-environments'
import { DateTime } from 'luxon'
import { CredentialExpirationCalculator, ExpirationPolicy, ExpirationPolicyRule, ExpirationPolicyService } from 'se-expiration-calculator'
import { EligibilityService } from './eligibility.service'

export interface CredentialListItem {
  chip: any
  name: string
  personaId: number
  personaName: string
  profileImage: string
  validityDates: string
}

const CRED_STATUS_MAP = {
  active: { text: 'Active', color: 'green' },
  expired: { text: 'Expired', color: 'red' },
  expiringSoon: { text: 'Expiring Soon', color: 'orange' }
}

const STATUS_SORT_WEIGHTS = {
  active: 1,
  expiringSoon: 2,
  expired: 3
}

@Injectable({
  providedIn: 'root',
})
export class CredentialListItemsService {

  constructor(
    private eligibilityService: EligibilityService
  ) { }

  public async getListItems(organizationId: number, personaIds: number[]): Promise<CredentialListItem[]> {
    const categories = await this.eligibilityService.getCredentialCategories(organizationId).toPromise()
    const activeCategories = categories.filter((category) => category.has_credentials)
    const typeAndSystemCreds =  await this.getOriginatorSystemAndTypeCredentials(activeCategories, organizationId, personaIds)
    const systemCreds = await this.getOriginatorSystemCredentials(activeCategories, organizationId, personaIds)
    const credentials = [...typeAndSystemCreds, ...systemCreds]
    const listItems = await this.getCredentialListItems(credentials, organizationId)
    return this.sortStatus(listItems)
  }

  public sortStatus(listItems: CredentialListItem[]): CredentialListItem[] {
    return listItems.sort((first, second) => {
      const firstWeight = STATUS_SORT_WEIGHTS[first.chip.status]
      const secondWeight = STATUS_SORT_WEIGHTS[second.chip.status]
      if (firstWeight > secondWeight) return 1
      if (firstWeight < secondWeight) return -1

      if (first.personaName > second.personaName) return 1
      if (first.personaName < second.personaName) return -1

      return 0
    })
  }

  private async getOriginatorSystemAndTypeCredentials(
    activeCategories: any[], organizationId: number, personaIds: number[]
  ): Promise<any[]> {
    const originatorSystemAndType = activeCategories.reduce((acc, cur) => {
      if (cur.originator_system && cur.originator_type) {
        acc.rule_system.push(cur.originator_system)
        acc.rule_type.push(cur.originator_type)
      }
      return acc
    }, { rule_system: [], rule_type: [] })
    return this.getCredentials(originatorSystemAndType, organizationId, personaIds)
  }

  private async getOriginatorSystemCredentials(activeCategories: any[], organizationId: number, personaIds: number[]): Promise<any[]> {
    const originatorSystemOnly = activeCategories.reduce((acc, cur) => {
      if (cur.originator_system && !cur.originator_type) {
        acc.rule_system.push(cur.originator_system)
      }
      return acc
    }, { rule_system: [] })
    return this.getCredentials(originatorSystemOnly, organizationId, personaIds)
  }

  private async getCredentials(params: any, organizationId: number, personaIds: number[]): Promise<any[]> {
    if (!params.rule_system.length) {
      return []
    }
    const credentialRequestBody: any = {
      all_organization_ids: organizationId,
      persona_ids: personaIds,
      ...params
    }
    return this.eligibilityService.findCredentials(credentialRequestBody).toPromise()
  }

  private async getCredentialListItems(credentials: any[], organizationId: number): Promise<CredentialListItem[]> {
    if (!credentials.length) {
      return []
    }

    const expirationPolicy = await this.getExpirationPolicy(organizationId)
    return credentials.map((credential) => {
      const expiresAt = this.calculateExpiration(credential, expirationPolicy)
      return {
        chip: this.calculateStatus(credential, expiresAt, expirationPolicy),
        name: credential.credential_label,
        personaId: credential.persona_id,
        personaName: credential.name,
        profileImage: this.profileImage(credential.profile_image),
        validityDates: this.getValidityDates(credential.effective_at, expiresAt)
      }
    })
  }

  private async getExpirationPolicy(organizationId: number): Promise<ExpirationPolicy> {
    const expirationPolicyService = new ExpirationPolicyService()
    const expirationPolicyEnv = this.getExpirationPolicyEnv()
    const expirationPolicy = await expirationPolicyService.load(`persona-eligibility-${organizationId}`, expirationPolicyEnv)
    return expirationPolicy || expirationPolicyService.load('persona-eligibility-default', expirationPolicyEnv)
  }

  private getValidityDates(effectiveAt: string, expiresAt: string): string {
    const effectiveAtDate = this.dateFormat(effectiveAt)
    const expiresAtDate = this.dateFormat(expiresAt)
    return expiresAtDate ? `${effectiveAtDate} - ${expiresAtDate}` : effectiveAtDate
  }

  private getExpirationPolicyEnv(): string {
    if (environment.production) {
      return environment.name
    }
    return 'staging'
  }

  private profileImage(profileImage: any): string {
    if (profileImage) {
      const cropIcon = profileImage.find((image: any) => image.image_type === 'crop_icon')
      if (cropIcon && cropIcon.url) {
        return cropIcon.url.replace(/_https/, '')
      }
    }
    return ''
  }

  private calculateExpiration(credential: any, expirationPolicy: ExpirationPolicy): string {
    let date: Date
    try {
      date = CredentialExpirationCalculator.credentialExpiration(
        expirationPolicy,
        'complete',
        DateTime.fromISO(credential.completed_at || credential.effective_at).toJSDate(),
        credential.rule_system,
        credential.rule_type,
        credential.rule_value
      )
    } catch (error) { /* noop */ }
    return (date ? date.toISOString() : credential.expires_at)
  }

  private calculateStatus(credential: any, expires_at: string, expirationPolicy: ExpirationPolicy): any {
    let status = 'active'
    if (expires_at) {
      const expires: DateTime = DateTime.fromISO(expires_at)
      let earlyRenewalStart: DateTime
      const today: DateTime = DateTime.now()
        .setZone(expirationPolicy.timezone)
        .startOf('day')
        .toUTC()

      status = 'expired'
      if (expires > today) {
        const condition: any = {
          type: 'credential',
          rule_system: credential.originator_system
        }

        if (condition.rule_system !== 'SeLitmosService') {
          condition.rule_type = credential.originator_type
        }

        const matchedRule = this.findRule(condition, today.toJSDate(), expirationPolicy)
        if (matchedRule) {
          if (matchedRule.term === 'seasons') {
            let renewalYearOffset = matchedRule.period - 1
            if (matchedRule.season.renewal_month > matchedRule.season.expiration_month) {
              renewalYearOffset += 1
            }

            earlyRenewalStart = DateTime.fromObject({
              year: expires.year - renewalYearOffset,
              month: matchedRule.season.renewal_month,
              day: matchedRule.season.renewal_day
            }).setZone(expirationPolicy.timezone)
              .startOf('day')
              .toUTC()
          } else {
            earlyRenewalStart = expires.minus({days: 60})
          }

          status = today >= earlyRenewalStart ? 'expiringSoon' : 'active'
        } else {
          status = expires.minus({days: 60}) <= today ? 'expiringSoon' : 'active'
        }
      }
    }
    return {
      text: CRED_STATUS_MAP[status]?.text || status,
      color: CRED_STATUS_MAP[status]?.color || 'grey',
      status
    }
  }

  private findRule(condition: any, date: Date, expirationPolicy: ExpirationPolicy): ExpirationPolicyRule {
    for (const rule of expirationPolicy.rules.filter(r => ExpirationPolicy.isRuleEffective(r, date))) {
      const matchedCondition = rule.conditions.find(c => (condition.type ? c.type === condition.type : true) &&
          (condition.rule_system ? c.rule_system === condition.rule_system : true) &&
          (condition.rule_type ? c.rule_type === condition.rule_type : true))
      if (matchedCondition) {
        return rule
      }
    }
  }

  private dateFormat(date: string): string {
    const dateTime = DateTime.fromISO(date)
    if (dateTime.isValid) {
      return dateTime.toFormat('MMM d, yyyy')
    }
  }

}
