<div class="pl-pane__container--horizontal">
  <app-nav-bar></app-nav-bar>
  <div *ngIf="!organizations?.length && !loading" class="pl-empty-state">
    <se-fe-empty-state seFeDataTitle="{{ 'MY_ORGANIZATIONS.EMPTY.header' | translate }}">
      <se-fe-icon seFeEmptyStateIcon seFeDataName="question_thin" seFeDataSize="950" seFeDataColor="gray-900"></se-fe-icon>
      {{ 'MY_ORGANIZATIONS.EMPTY.message' | translate }}
    </se-fe-empty-state>
  </div>

  <div
    *ngIf="organizations?.length"
    class="pl-pane__info--full-screen">
    <div pl-grid="loose align-center">
      <div pl-grid-el pl-grid="1/1@phone">
        <se-fe-card id="myse-orgs-container-card">
          <se-fe-card-header [seFeDataTitle]="'MY_ORGANIZATIONS.title' | translate" [seFeDataHeavyBorder]="true"></se-fe-card-header>
          <se-fe-card-content>
            <se-fe-spinner *ngIf="loading" [seFeDataCenter]="true" [seFeDataSize]="900"></se-fe-spinner>
            <se-fe-list *ngIf="!loading">
              <se-fe-list-item
                *ngFor="let org of organizations">
                <se-fe-header size="600"
                  [seFeDataTitle]="org.name">
                  <div seFeHeaderVisual>
                    <se-fe-avatar
                      seFeDataType="org"
                      [seFeDataIdentity]="org.name"
                      [seFeDataImgUrl]="(org.logo?.is_default ? '' : org.logo?.large_logo_url)"
                      seFeDataSize="200">
                    </se-fe-avatar>
                  </div>
                  <div seFeHeaderActions
                    *ngIf="orgSitesMenuLinks">
                    <se-fe-icon-button [seFeMenuTrigger]="orgSitesMenuLinks[org.id]?.name" seFeDataEmphasis="low">
                      <se-fe-icon
                        [seFeDataMargin]="false"
                        seFeDataName="more_verticle"
                        seFeDataSize="700"
                        seFeDataAriaLabel="website links">
                      </se-fe-icon>
                    </se-fe-icon-button>
                    <se-fe-menu
                      seFeDataMinWidth="150"
                      [seFeDataOptions]="orgSitesMenuLinks[org.id]">
                    </se-fe-menu>
                  </div>
                </se-fe-header>
              </se-fe-list-item>
            </se-fe-list>
          </se-fe-card-content>
          <se-fe-card-footer>
            <se-fe-pagination
              [seFeDataPaginationOptions]="paginationOptions"
              (seFePaginationChange)="handlePagination($event)">
            </se-fe-pagination>
          </se-fe-card-footer>
        </se-fe-card>
      </div>
    </div>
  </div>
</div>

