import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { UserPreferenceService } from '@se-po/shared-data-access-services'
import { PersonaImageModalComponent } from '@se-po/shared-feature-persona-image-modal'
import { take } from 'rxjs/operators'
import { SeFeMenuOptions, SeFeMenuSection } from 'se-fe-menu'
import { PersonaAccount, RelatedGuardiansResult, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'

@Component({
  selector: 'se-po-household-side-panel',
  templateUrl: './household-side-panel.component.html',
  styleUrls: ['./household-side-panel.component.scss'],
})
export class HouseholdSidePanelComponent implements OnInit {
  @Output() public edit = new EventEmitter<UserProfile>()
  @Output() public guardianUpdate = new EventEmitter<void>()
  @Output() public merge = new EventEmitter<UserProfile[]>()
  @Output() public personaEdited = new EventEmitter<number>()
  @Output() public profileRemove = new EventEmitter<UserProfile>()
  @Output() public personaIdChange = new EventEmitter<number>()

  @ViewChild('personaImageModal') public personaImageModal: PersonaImageModalComponent

  public isSelf = true
  public isGuardian: boolean
  public loading: boolean
  public menuOptions: SeFeMenuOptions
  public mySeVersion: string | number
  public persona: Persona
  public profile: UserProfile | RelatedGuardiansResult

  private _guardians: RelatedGuardiansResult[]
  private _personaId: number
  private _profiles: UserProfile[]

  constructor(
    public userPrefService: UserPreferenceService
  ) {
    //noop
  }

  public get guardians(): RelatedGuardiansResult[] {
    return this._guardians
  }

  public get personaId(): number {
    return this._personaId
  }

  public get profiles(): UserProfile[] {
    return this._profiles
  }

  @Input() public set guardians(guardians: RelatedGuardiansResult[]) {
    this._guardians = guardians
    this.updateSidePanel()
  }

  @Input() public set personaId(personaId: number) {
    if (typeof(personaId) == 'number' || !personaId) {
      this._personaId = personaId || null
      this.updateSidePanel()
      this.personaIdChange.emit(this.personaId)
    }

  }

  @Input() public set profiles(profiles: UserProfile[]) {
    this._profiles = profiles
    this.updateSidePanel()
  }

  public ngOnInit(): void {
    this.loading = true
    this.setUserPref()
  }

  public setUserPref(): void {
    let subscription
    subscription = this.userPrefService.mySeVersion().pipe(
      take(1)
    ).subscribe({
      next: result => {
        this.mySeVersion = result
        if (subscription) {
          subscription.unsubscribe()
        }
        this.loading = false
      }
    })
    subscription = null
  }

  public onEdit(p: UserProfile): void {
    this.edit.emit(p)
  }

  public onGuardianUpdate(): void {
    this.guardianUpdate.emit()
  }

  public onMerge(p: UserProfile[]): void {
    this.merge.emit(p)
  }

  public onPersonaEdit(): void {
    this.personaEdited.emit(this.persona.id)
  }

  public onProfileRemove(p: UserProfile): void {
    this.profileRemove.emit(p)
  }

  public onUploadImage(): void {
    this.personaImageModal.open(this.persona.id)
  }

  private updateSidePanel(): void {
    this.profile = (this.profiles || []).find(p => p.persona.id === this.personaId)
      || (this.guardians || []).find(p => p.user.self_persona.id === this.personaId)
    if (!this.profile) return

    if ('user' in this.profile) {
      this.persona = this.profile.user.self_persona
      this.isGuardian = true
      this.isSelf = false
      this.menuOptions = null
    } else {
      this.isSelf = this.profile.relationship === 'self'
      this.isGuardian = false
      this.persona = this.profile.persona
      this.setMenuOptions(this.profile)
    }
  }

  private setMenuOptions(profile: UserProfile): void {
    const sections: SeFeMenuSection[] = []
    if (profile.access !== 'viewer') {
      sections.push(
        {
          menuItems: [
            {
              text: 'Upload Profile Image',
              action: () => this.onUploadImage()
            }
          ]
        },
        {
          menuItems: [
            {
              text: 'Edit Profile',
              action: () => this.onEdit(profile as UserProfile)
            }
          ]
        }
      )
    }

    // You can only opt to merge together non self profiles that you own
    if (profile.relationship === 'other' && profile.access === 'owner') {
      const otherProfiles = this.profiles?.filter(p => p.id !== profile.id && p.relationship === 'other' && p.access === 'owner')
      if (otherProfiles.length) {
        const mergeItems = []
        otherProfiles.forEach(otherProfile => {
          mergeItems.push({
            text: otherProfile.persona.full_name,
            action: () => this.onMerge([otherProfile, profile] as UserProfile[])
          })
        })
        sections.push(
          {
            header: 'Merge Profile With',
            divider: false,
            menuItems: mergeItems
          }
        )
      }
    }

    if (profile.relationship === 'other') {
      sections.push(
        {
          menuItems: [
            {
              text: 'Remove Profile',
              action: () => this.onProfileRemove(profile as UserProfile)
            }
          ]
        }
      )
    }

    this.menuOptions = { name: 'profileSidePanelMenu', sections }
  }
}
