import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators'
import { Sale, Persona, PaginationSettings } from '../models'
import { ColumnSort } from '../models/column-sort.interface'
import { InvoiceService } from '../services/invoice.service'
import { PaymentService } from '../services/payment.service'
import { SpinnerService } from '../services/spinner.service'

@Component({
  selector: 'app-bills-list',
  templateUrl: './bills-list.component.html'
})
export class BillsListComponent implements OnInit, OnDestroy {

  public personaSelf: Persona
  public bills: Sale[] = []
  public pageMetadata: any
  public loading = true
  public searchTerm: string
  public displayDiscounts: boolean
  public displayCredits: boolean

  private searchTermChanged: Subject<string> = new Subject<string>()
  private sortChanged: Subject<any> = new Subject<any>()
  private invoiceSubscription: Subscription

  private _sortedColumn: ColumnSort = { column: 'display_priority', sort: 'asc'}
  private _paginationSettings: PaginationSettings = { page: 1, per_page: 25 }

  constructor(private activatedRoute: ActivatedRoute,
    private invoiceService: InvoiceService,
    private paymentService: PaymentService,
    private spinner: SpinnerService) {
    // noop
  }

  public get paginationSettings(): PaginationSettings {
    return this._paginationSettings
  }

  public get sortedColumn(): ColumnSort {
    return this._sortedColumn
  }

  public set paginationSettings(val: PaginationSettings) {
    if (this._paginationSettings.page !== val.page ||
      this._paginationSettings.per_page !== val.per_page) {
        this._paginationSettings = val
        this.loadResults()
      }
  }

  public set sortedColumn(val: ColumnSort) {
    this._sortedColumn = val
    this.sortChanged.next(val)
  }

  public ngOnDestroy(): void {
    this.invoiceSubscription.unsubscribe()
    this.searchTermChanged.complete()
    this.sortChanged.complete()
  }

  public ngOnInit(): void {
    this.spinner.start()
    this.personaSelf = this.activatedRoute.snapshot.data.personaSelf
    this.invoiceSubscription = this.invoiceService.current.subscribe((results) => {
      if (!results) return
      this.bills = results

      this.displayDiscounts = this.bills.some(b => b.discounted)
      this.displayCredits = this.bills.some(b => b.credited)

      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.pageMetadata = results ? this.bills['metadata'] : {}
      if (this.pageMetadata) {
        this.paginationSettings.page = this.pageMetadata?.pagination.current_page
      }
      this.loading = false
      this.spinner.stop()
    })

    this.searchTermChanged.pipe(
      debounceTime(500),
      filter((term: string) => term.length !== 1),
      distinctUntilChanged())
      .subscribe(
        (val) => {
          this.loadResults()
        }
      )
    this.sortChanged.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(
        (val) => {
          this.loadResults()
        }
      )

    this.loadResults()
  }

  public search(term: string): void {
    this.searchTermChanged.next(term)
  }

  public showMakePayment(bill: Sale): boolean {
    return (bill.display_status === 'open' && !bill.payment_plan_payment_method) || bill.display_status === 'past_due'
  }

  public makePayment(bill: Sale): void {
    this.paymentService.makePayment(bill.sale_number)
  }

  public defineStatus(bill: Sale): string {
    if (this.autopay(bill)) {
      return 'autopay'
    }
    else if (bill.display_status === 'voided') {
      return 'voided'
    }
    else {
      return 'paid_in_full'
    }
  }

  public loadResults(): void {
    const params: any = { user_id: this.personaSelf?.user.id, ...this.paginationSettings }
    const term: string = this.searchTerm
    if (term && term.length > 1) {
      params.search_fields = 'sale_number,sales.description'
      params.search_term = term
    }

    if (this.sortedColumn?.column) {
      params[`order[${this.sortedColumn.column}]`] = this.sortedColumn.sort
    }
    this.invoiceService.find(params)
  }

  private autopay(bill: Sale) {
    return bill.display_status === 'open' && bill.payment_plan_payment_method
  }
}
